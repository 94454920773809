// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCv1XVP8NKnIQJMAWi6m2WDFf9NZ3jAVY4',
  authDomain: 'isopnm-fd8b6.firebaseapp.com',
  projectId: 'isopnm-fd8b6',
  storageBucket: 'isopnm-fd8b6.appspot.com',
  messagingSenderId: '637141458655',
  appId: '1:637141458655:web:adef3a5c04a683995b136a',
  measurementId: 'G-E77DS16QKT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

// export utils/refs
export { db, analytics };
